
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Body from "./components/Body";
import Intro from "./components/Intro";
import Projects from "./components/Projects";
function App() {
    return (
        <div className="App">
            <Header />
            <Body>
                <Intro />
                {/*<Projects />*/}
            </Body>
            <Footer />
        </div>
    );
}

export default App;
