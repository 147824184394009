import React from 'react';

import styled from 'styled-components'
const StyledProjects = styled.div`
    background-color: purple;
    color: white;
    margin: 50px;
    border-radius: 25px;
`;
const Projects = () => (
    <StyledProjects>
        <h4>These are my projects.</h4>
    </StyledProjects>
    )
    export default Projects