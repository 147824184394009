import React from 'react';

import styled from 'styled-components'
const StyledFooter = styled.footer`
    padding: 10px 0;
    background-color: purple;
    color: white;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    max-width: 100%;
    max-height: 40px;
    padding:  20px;
    font-family: 'thefont-family';
    a:link {
        color: white;
    }
    a:visited {
        color: black;
    }
`;
const Footer = () => (
    <StyledFooter>
        <a href="https://github.com/amartin999">Visit my Github here!</a>
        <p>Copyrigt 2022 by Allie Martin.  v1.00</p>
    </StyledFooter>
)
export default Footer