import React from 'react';

import styled from 'styled-components'
const StyledHeader = styled.header`
    padding: 10px 0;
    background-color: purple;
    color: white;
    display: flex;
    justify-content: space-between;
    max-height: 40px;
    padding: 20px;
    font-family: 'thefont-family';
    a:link {
        color: white;
    }
    a:visited {
        color: black;
    }
`;


const Header = () => (
    
    <StyledHeader>
        <h3>Hello!</h3>
        <a href="https://github.com/amartin999">Visit my Github here!</a>
    </StyledHeader>
)
export default Header