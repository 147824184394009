import React from 'react';

import styled from 'styled-components'

const StyledBody = styled.body`
    background-color: navy;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    font-family: 'thefont-family';
`;
const Body = (props) => {
    
    return(
        <StyledBody>{props.children}</StyledBody>
    )
    }
export default Body