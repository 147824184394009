import React from "react";

import styled from "styled-components";
import night_sky from "../assets/night_sky.jpg";

const StyledIntro = styled.div`
    background-color: purple;
    color: white;
    display: grid;
    gap: 30px;
    justify-content: space-between;
    grid-template-rows: auto;
    grid-template-columns: auto auto;
    align-items: center;
    margin: 50px;
    padding: 30px;
    border-radius:  25px;
    font-family: 'thefont-family';
    @media (max-width: 768px) {
        grid-template-columns: auto;
        grid-template-rows: auto auto
    }
    img {
        width: 100%;
        max-width: 250px;
    }
    
`;
const StyledParagraph = styled.p``;
const Intro = () => (
    <StyledIntro>
        <img src={night_sky} />
        <StyledParagraph>
            <p>
                My name is Allie Martin and I am a freelance full-stack
                developer. I am especially interested in web development and
                database administration. This website is a personal project of
                mine as well as a place to display my other projects.{" "}
            </p>
        </StyledParagraph>
    </StyledIntro>
);
export default Intro;
